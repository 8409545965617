import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';
import { Mousewheel, Pagination } from 'swiper/modules';
import Recorder from '../Recorder/recorder';

import InitalPage from '../Screen/intialpage';
import storybookAssets from '../Screen/storybook_data';
import { EffectCards } from 'swiper/modules';
import 'swiper/css/effect-cards';
import ConclusionPage from '../Screen/conclusion'
import { Box, Typography } from '@mui/material';
import { RotateLeft } from '@mui/icons-material';

export default function SwiperAnim() {
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isLandscape) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Please rotate your device to landscape mode
        </Typography>
        <RotateLeft sx={{fontSize :'150px'}}/>
      </Box>
    );
  }

  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper responsiveLimiter"
      >
        <SwiperSlide><InitalPage /></SwiperSlide>
        {storybookAssets.map((item) => (
          <SwiperSlide key={item.id}>
            <Recorder image={item.image} caption={item.caption} audio={item.audio} />
          </SwiperSlide>
        ))}
        <SwiperSlide><ConclusionPage /></SwiperSlide>
      </Swiper>
    </>
  );
}