import { Typography } from '@mui/material'
import React from 'react'
import instructions from '../Recorder/Assets/instructions.png'
import { Email, Feedback, StarRate } from '@mui/icons-material'

function InitialPage() {
    const handleFeedbackClick = () => {
        console.log("handle feedback")
    }
    const handleEmailClick = () => {
        console.log("handle email")
    }
    const handleRateClick = () => {
        console.log("handle rate")
    }
    return (
      <div className='init_page'>
        <Typography sx={{ marginBottom: 2, fontSize:"45px", fontFamily:"Montserrat, sans-serif", fontWeight:"800"}}>
          Welcome!
        </Typography>
        <Typography sx={{ fontFamily:"Montserrat, sans-serif" }}>
          Beta testing allows users to record their voice based on an image and caption and receive ratings on their pronunciation.
          <br></br>يسمح الاختبار التجريبي للمستخدمين بتسجيل صوتهم بناء على صورة وتسمية توضيحية والحصول على تقييمات على نطقهم.
        
        </Typography>

        {/* <div className='buttonbar'>
          <button onClick={() => handleFeedbackClick()} className='buttonfirstpage'><Feedback style={{ width: '16px', height: '16px', marginRight:'10px' }}/> Feedback تعليق</button>
          <button onClick={() => handleEmailClick()} className='buttonfirstpage'><Email style={{ width: '16px', height: '16px', marginRight:'10px' }}/>Email بريد</button>
          <button onClick={() => handleRateClick()} className='buttonfirstpage'><StarRate style={{ width: '16px', height: '16px', marginRight:'10px' }}/>Rate معدل</button>
        </div> */}
        <div>
            <img src={instructions} className='instructions'/>
        </div>
      </div>
    );
  }
  

export default InitialPage
