import { Typography } from '@mui/material'
import React from 'react'
import instructions from '../Recorder/Assets/instructions.png'
import { Email, Feedback, StarRate } from '@mui/icons-material'

function ConclusionPage() {
    const handleFeedbackClick = () => {
        console.log("handle feedback")
    }
    const handleEmailClick = () => {
        console.log("handle email")
    }
    const handleRateClick = () => {
        console.log("handle rate")
    }
    return (
      <div className='final_page'>
        <Typography sx={{ marginBottom: 2, fontSize:"45px", fontFamily:"Montserrat, sans-serif", fontWeight:"800"}}>
          The END! 👋 <br></br>
          النهاية
        </Typography>
      </div>
    );
  }
  

export default ConclusionPage
