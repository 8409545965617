const storybookAssets = [
    {
        id: 1,
        image: 'imagepup',
        caption: 'It`s a Pup',
    },
    {
        id: 2,
        image: 'imageant',
        caption: 'It`s an ant',
    },
    {
        id: 3,
        image: 'imagelikebees',
        caption: 'I Like Bees',
    },
    {
        id: 4,
        image: 'imagedontlikepeas',
        caption: 'I Don`t Like Peas',
    },
    {
        id: 5,
        image: 'imagedontlikesharks',
        caption: 'I Don`t Like Sharks',
    },
    {
        id: 6,
        image: 'imagedontlikespiders',
        caption: 'I Don`t Like Spiders',
    },
    {
        id: 7,
        image: 'imagetheresgiraffe',
        caption: 'Look there`s giraffe',
    }
];

export default storybookAssets;

