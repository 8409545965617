import './App.css';
import React, { useState, useEffect } from 'react';
import { AirportShuttle, ArrowRight, Copyright, Curtains, VerifiedUserOutlined,  } from '@mui/icons-material';
import OTPInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import { Snackbar } from '@mui/base';
import { Alert } from '@mui/material';
import Recorder from './Recorder/recorder';
import SwiperAnim from './Swiper/swiper';

import {
  PICTURELINGO_PLAYGROUND_PIN
} from './config';

function App() {
  const [otp, setOtp] = useState('');
  const [authorized, setAuthorized] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const isAuthorized = localStorage.getItem('authorized') === 'true';
    setAuthorized(isAuthorized);
  }, []);

  const handleContinue = () => {
    const pin = PICTURELINGO_PLAYGROUND_PIN.toString();
    if (otp === pin) {
      setAuthorized(true);  
      setSuccess(true);
      localStorage.setItem('authorized', 'true');
    } else {
      setAuthorized(false);
      setError(true);
      localStorage.removeItem('authorized');
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  }

  if (authorized) {
    return (
      <>
      <img src='https://picturelingo.com/media/mr2l0x3y/website-logo-150.png' className='logo'/>
      <span class="beta">Picture Lingo AI Beta V1.000.5<VerifiedUserOutlined/></span>
      <div className="authorized-page">
        <SwiperAnim/>
      </div>
      </>
    );
  }

  return (
    <div className="app">
      <div className="login-container">
        <div className="header">
          <h1 class="gochi"><span class='blue'>Welcome to</span><br></br> <span class='red'>Playground </span></h1>
          <p class="spcmono">Picture Lingo AI Beta Tester</p>
        </div>
        <form className="pincode-form">
          <label htmlFor="pincode">Enter PIN:</label>
          <div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props}  />}
            renderSeparator={<span>-</span>}
            inputStyle="inputStyleT"
          />
          </div>
        </form>
        <button className="continue-button" onClick={handleContinue}>
          Continue
          <ArrowRight/>
        </button>
        <hr></hr>
        <div className="footer">
          <p className='spcmono'>Powered by</p>
          <img src='https://picturelingo.com/media/mr2l0x3y/website-logo-150.png' width={"50px"} height={"50px"}/>
        </div>
      </div>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Authorized!
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Not Authorized! Incorrect PIN! Connect with afox@picturelingo.com for more
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
